// TODO: Expand this into multiple files as it grows.

import { type InjectionKey, type Ref, ref } from "vue"
import {
  type CompanyBenefitsApi,
  type CompanyHealthContribution,
  type MemberEnrollment,
  type MemberDetails,
  type ActiveEmployeeBenefit,
  type OpenEmployeeEnrollment,
  type PastEmployeeBenefit,
  type QuoteStatuses,
  type BenefitsOverviewEnrollments,
  type OverviewSummary,
  type Viewer,
  type MemberOfferingData,
  PayrollProvider,
  type SelectionWindows,
  type LifeEnrollment,
  type DisabilityEnrollments,
  type MemberSelections,
} from "@/types"

export const ViewerSymbol = Symbol("Viewer") as InjectionKey<Ref<Viewer>>
export const viewerDefault = ref({
  enabled_feature_flags: [],
  member: {
    id: "",
    abilities: [],
    preferred_name: "",
    first_name: "",
    last_name: "",
    payroll_provider: PayrollProvider.JUSTWORKS_PEO,
    health_advocate_enabled: false,
    back_button_routing_text: "",
    back_button_routing_url: "",
    canonical_id: "",
  },
  company: {
    id: "",
    name: "",
    primary_location: {
      street_one: "",
      street_two: "",
      city: "",
      state: "",
      zip_code: "",
    },
  },
})

type ErrorCode =
  | 400
  | 900
  | 901
  | 401
  | 403
  | 404
  | 422
  | 500
  | 200
  | 406
  | null

export const CompanyBenefitsFetchSymbol = Symbol(
  "CompanyBenefits"
) as InjectionKey<{
  getCompanyBenefits: () => Promise<void>
  isFetchingCompanyBenefits: Ref<boolean>
  companyBenefits: Ref<CompanyBenefitsApi>
  companyBenefitsStatus: Ref<ErrorCode>
}>

export const companyBenefitsFetchDefault = {
  getCompanyBenefits: () => Promise.resolve(),
  isFetchingCompanyBenefits: ref(false),
  companyBenefits: ref({
    enrollments: [],
    offerings: [],
    active_employees_enrolled: {},
    upcoming_employees_enrolled: {},
    errors: [],
  }),
  companyBenefitsStatus: ref(null),
}

export const CompanyHealthContributionsFetchSymbol = Symbol(
  "CompanyHealthContributions"
) as InjectionKey<{
  getCompanyHealthContributions: () => Promise<void>
  isFetchingCompanyHealthContributions: Ref<boolean>
  companyHealthContributions: Ref<CompanyHealthContribution[]>
}>

export const companyHealthContributionsFetchDefault = {
  getCompanyHealthContributions: () => Promise.resolve(),
  isFetchingCompanyHealthContributions: ref(false),
  companyHealthContributions: ref([]),
}

export const MemberEnrollmentsFetchSymbol = Symbol() as InjectionKey<{
  getMemberEnrollments: () => Promise<void>
  isFetchingMemberEnrollments: Ref<boolean>
  memberEnrollments: Ref<MemberEnrollment[]>
  memberEnrollmentsStatus: Ref<ErrorCode>
  memberEnrollmentsErrors: Ref<{ method: string; error: string }[]>
}>

export const memberEnrollmentsFetchDefault = {
  getMemberEnrollments: () => Promise.resolve(),
  isFetchingMemberEnrollments: ref(false),
  memberEnrollments: ref([]),
  memberEnrollmentsStatus: ref(null),
  memberEnrollmentsErrors: ref([]),
}

export const memberHealthDetailsFetchDefault = {
  isFetchingMemberHealthDetails: ref(false),
  healthDetails: ref({ dependents: [] }),
}

export const MemberDetailsFetchSymbol = Symbol() as InjectionKey<{
  getMemberDetails: () => Promise<void>
  isFetchingMemberDetails: Ref<boolean>
  memberDetails: Ref<MemberDetails[]>
  memberDetailsStatus: Ref<ErrorCode>
}>

export const memberDetailsFetchDefault = {
  getMemberDetails: () => Promise.resolve(),
  isFetchingMemberDetails: ref(false),
  memberDetails: ref([]),
  memberDetailsStatus: ref(null),
}

export const MemberOfferingsFetchSymbol = Symbol() as InjectionKey<{
  isFetchingMemberOfferings: Ref<boolean>
  memberOfferings: Ref<MemberOfferingData[]>
}>

export const memberOfferingsFetchDefault = {
  isFetchingMemberOfferings: ref(false),
  memberOfferings: ref([]),
}

export const EmployeesActiveBenefitsFetchSymbol = Symbol() as InjectionKey<{
  fetchingActiveBenefits: Ref<boolean>
  activeBenefits: Ref<ActiveEmployeeBenefit[]>
}>

export const employeesActiveBenefitsFetchDefault = {
  fetchingActiveBenefits: ref(false),
  activeBenefits: ref([]),
}

export const EmployeesOpenEnrollmentsFetchSymbol = Symbol() as InjectionKey<{
  fetchingOpenEnrollments: Ref<boolean>
  openEnrollments: Ref<OpenEmployeeEnrollment[]>
}>

export const employeesOpenEnrollmentsFetchDefault = {
  fetchingOpenEnrollments: ref(false),
  openEnrollments: ref([]),
}

export const EmployeesPastBenefitsFetchSymbol = Symbol() as InjectionKey<{
  fetchingPastBenefits: Ref<boolean>
  pastBenefits: Ref<PastEmployeeBenefit[]>
}>

export const employeesPastBenefitsFetchDefault = {
  fetchingPastBenefits: ref(false),
  pastBenefits: ref([]),
}
export const CompanyQuotesFetchSymbol = Symbol() as InjectionKey<{
  getCompanyQuotes: () => Promise<void>
  isFetchingCompanyQuotes: Ref<boolean>
  companyQuotesData: Ref<QuoteStatuses>
}>

export const companyQuotesFetchDefault = {
  getCompanyQuotes: () => Promise.resolve(),
  isFetchingCompanyQuotes: ref(false),
  companyQuotesData: ref([]),
}

export const BenefitsOverviewFetchSymbol = Symbol() as InjectionKey<{
  benefitsOverview: Ref<BenefitsOverviewEnrollments>
  isFetchingBenefitsOverview: Ref<boolean>
}>

export const benefitOverviewFetchDefault = {
  isFetchingBenefitsOverview: ref(false),
  benefitsOverview: ref({
    companyName: "",
    message: "",
    contributions: false,
    enrollments: [],
  }),
}

export const benefitOverviewSummarySymbol = Symbol() as InjectionKey<{
  benefitOverviewSummaryData: Ref<OverviewSummary>
  isFetchingBenefitOverviewSummary: Ref<boolean>
}>

export const benefitOverviewSummaryFetchDefault = {
  isFetchingBenefitOverviewSummary: ref(false),
  benefitOverviewSummaryData: ref({ classes: [], records: [] }),
}

export const SelectionWindowsFetchSymbol = Symbol() as InjectionKey<{
  isFetchingSelectionWindows: Ref<boolean>
  selectionWindows: Ref<SelectionWindows>
  getSelectionWindows: () => Promise<void>
}>

export const selectionWindowsFetchDefault = {
  isFetchingSelectionWindows: ref(false),
  selectionWindows: ref([]),
  getSelectionWindows: () => Promise.resolve(),
}

export const MemberLifeEnrollmentFetchSymbol = Symbol() as InjectionKey<{
  isFetchingMemberLifeEnrollment: Ref<boolean>
  memberLifeEnrollment: Ref<LifeEnrollment>
}>

export const memberLifeEnrollmentFetchDefault = {
  isFetchingMemberLifeEnrollment: ref(false),
  memberLifeEnrollment: ref({
    start_date: new Date(),
    end_date: new Date(),
    member_id: "",
    status: "",
    type: "",
    name: "",
    coverage_tier: 0,
    has_plans: false,
  }),
}

export const MemberDisabilityEnrollmentsFetchSymbol = Symbol() as InjectionKey<{
  isFetchingMemberDisabilityEnrollments: Ref<boolean>
  memberDisabilityEnrollments: Ref<DisabilityEnrollments>
}>

export const memberDisabilityEnrollmentsFetchDefault = {
  isFetchingMemberDisabilityEnrollments: ref(false),
  memberDisabilityEnrollments: ref([]),
}

export const MemberSelectionsFetchSymbol = Symbol() as InjectionKey<{
  isFetchingMemberSelections: Ref<boolean>
  memberSelections: Ref<MemberSelections>
}>

export const memberSelectionsFetchDefault = {
  isFetchingMemberSelections: ref(false),
  memberSelections: ref([]),
}
export const MemberBenefits = Symbol() as InjectionKey<{
  isFetchingMemberDisabilityEnrollments: Ref<boolean>
  memberDisabilityEnrollments: Ref<DisabilityEnrollments>
}>

export type UnifiedPlanSelectionEnabledPayload = {
  enabled: boolean
}

export const UnifiedPlanSelectionEnabledSymbol = Symbol() as InjectionKey<{
  getIsUnifiedPlanSelectionEnabled: () => Promise<void>
  isFetchingUnifiedPlanSelectionEnabled: Ref<boolean>
  unifiedPlanSelectionEnabledPayload: Ref<UnifiedPlanSelectionEnabledPayload>
}>

export const unifiedPlanSelectionEnabledDefault = {
  unifiedPlanSelectionEnabledPayload: ref({ enabled: false }),
  getIsUnifiedPlanSelectionEnabled: () => Promise.resolve(),
  isFetchingUnifiedPlanSelectionEnabled: ref(false),
}
