// See BSMG-3163
/* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
import { createApp } from "vue"
import { datadogRum } from "@datadog/browser-rum"
import { FeatureFlagVuePlugin } from "@justworkshr/vue-open-feature-helpers"
import OktaVue from "@okta/okta-vue"
import { autoAnimatePlugin } from "@formkit/auto-animate/vue"
import { t } from "@/i18n"
import oktaAuth from "./oktaAuth"
import loadPendo from "./loadPendo"
import App from "./App.vue"
import router from "./router"
import "./validation"

if (import.meta.env.VITE_PROD === "true") {
  datadogRum.init({
    applicationId: `${import.meta.env.VITE_EMPLOYEE_DD_APPLICATION_ID}`,
    clientToken: `${import.meta.env.VITE_EMPLOYEE_DD_CLIENT_TOKEN}`,
    site: "datadoghq.com",
    service: "benefits-sage-employee",
    env: import.meta.env.VITE_PROD === "true" ? "prod" : "dev",
    version: `${import.meta.env.VITE_CODE_VERSION}`,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    defaultPrivacyLevel: "mask",
    enablePrivacyForActionName: true,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    allowedTracingUrls: [
      /https:\/\/.*\.justworks\.com/,
      /https:\/\/.*\.crimsonsrage\.com/,
    ],
    traceSampleRate: 100,
  })
}

const launchDarklyClientKey = `${
  import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_KEY || ""
}`

const onAuthRequired = function onAuthRequired(oktaAuth) {
  if (!oktaAuth.authStateManager.getPreviousAuthState()?.isAuthenticated) {
    oktaAuth.signInWithRedirect()
  }
}

loadPendo(import.meta.env.VITE_PENDO_API_KEY)

const app = createApp(App)
app.use(FeatureFlagVuePlugin, { clientKey: launchDarklyClientKey })
app.use(router)

app.use(OktaVue, {
  oktaAuth,
  onAuthRequired: () => {
    onAuthRequired(oktaAuth)
  },
})
app.use(autoAnimatePlugin)
app.mount("#app")
app.config.globalProperties.$t = t
